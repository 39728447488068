<template>
    <div class="modal-friends-filter">
        <div class="head">
            <i @click="$emit('close')" class="zmdi zmdi-close" />
            <div class="title" v-html="$translate('FILTER')" />
        </div>
        <div class="body">
            <SuperFilterMob @onClickSuperSearch="$emit('close')" :filters="options.filters" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFriendsFilter',
    props: ['options'],
}
</script>
<style scoped lang="scss">
.head {
    z-index: 3;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid $grey-03;

    .title {
        font-size: 16px;
        text-align: center;
        width: 100%;
        margin-left: 10px;
        @include f-medium;
    }
}
.body {
    margin-top: 80px;
}
</style>
